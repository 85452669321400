import type { LotsResponseData } from '@/types/api-response/lots';
import type { MapMarker } from '@/types/mapMarkers';
import { format } from './numberFormat';
import type { LandUseGroupItem } from '@/types/api-response/settings';
import type { County } from '@/types/counties';

const getLandUseCode = (code: number) => {
  const settings = localStorage.getItem('settings')
  if (! settings) return code 

  const landUseCodes: LandUseGroupItem[] = 
    (JSON.parse(settings)).land_use_codes 
  return landUseCodes.find(landUse => 
    +landUse.code === code)?.group ?? code
}

const getCountyById = (id: string) => {
  const settings = localStorage.getItem('settings')
  if (! settings) return null 

  const counties: County[] = (JSON.parse(settings)).counties?.data 
  return counties.find(county => county.id === id)?.attributes?.name
}

//Sorting of marker data now happens on the backend
export const sortMarkerData = (
  markers: MapMarker[] | LotsResponseData[]
): MapMarker[] | LotsResponseData[] => {
  if (!markers || (Array.isArray(markers) && markers.length === 0)) {
    return [];
  }

  //eslint-disable-next-line complexity
  const initialSort = markers.slice().sort((a, b) => {
    if ((a.attributes?.lat ?? 0) !== (b.attributes?.lat ?? 0)) {
      return (b.attributes?.lat ?? 0) - (a.attributes?.lat ?? 0);
    }
    return (b.attributes?.lng ?? 0) - (a.attributes?.lng ?? 0);
  })
  
  const sortedByNearest = sortMarkersByNearestNeighbor(
    initialSort as MapMarker[])

  return sortedByNearest.map(marker => ({
    ...marker,
    land_use: getLandUseCode(+marker!.attributes.land_use_code!),
    county: getCountyById(marker!.attributes.county_id),
    formatted_price: format(marker!.attributes?.estimated_price!, true)
  })) as MapMarker[];
}

export const appendLotMarkerDetails = (markers: MapMarker[]) => {
  return markers.map(marker => ({
    ...marker,
    land_use: getLandUseCode(+marker!.attributes.land_use_code!),
    county: getCountyById(marker!.attributes.county_id),
    formatted_price: format(marker!.attributes?.estimated_price!, true)
  })) as MapMarker[];
}

function calculateDistance(marker1: MapMarker, marker2: MapMarker) {
  return Math.hypot(
    marker1.attributes?.lat! - marker2.attributes?.lat!,
    marker1.attributes?.lng! - marker2.attributes?.lng!
  )
}

function sortMarkersByNearestNeighbor(markers: MapMarker[]) {
  const sortedMarkers = [markers.shift()]; // Start with the first marker
  
  while (markers.length > 0) {
    const lastMarker = sortedMarkers[sortedMarkers.length - 1];
    let nearestIndex = 0;
    let shortestDistance = Infinity;
    
    markers.forEach((marker, index) => {
      const distance = calculateDistance(lastMarker!, marker);
      if (distance < shortestDistance) {
        shortestDistance = distance;
        nearestIndex = index;
      }
    });
    
    sortedMarkers.push(markers.splice(nearestIndex, 1)[0]);
  }
  
  return sortedMarkers;
}